// ROBOTO

@font-face {
  font-family: 'roboto-black';
  src: url('../assets/fonts/Roboto-Black.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-black-italic';
  src: url('../assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-bold';
  src: url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-bold-condensed';
  src: url('../assets/fonts/Roboto-BoldCondensed.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-condensed-italic';
  src: url('../assets/fonts/Roboto-BoldCondensedItalic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-bold-italic';
  src: url('../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-condensed';
  src: url('../assets/fonts/Roboto-Condensed.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-condensed-italic';
  src: url('../assets/fonts/Roboto-BoldCondensedItalic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-italic';
  src: url('../assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-light';
  src: url('../assets/fonts/Roboto-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-light-italic';
  src: url('../assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-medium';
  src: url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-medium-italic';
  src: url('../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-regular';
  src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-thin';
  src: url('../assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'roboto-thin-italic';
  src: url('../assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-style: normal;
} ;
